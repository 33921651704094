import { DateTime } from 'luxon'
import RestClient from '../restClient/restClient'
import { v4 as uuidv4 } from 'uuid'
import Dexie from 'dexie'
import { ClickContext } from '../../components/buttons/button/button'

export enum EventType {
  CLICK = 'CLICK',
  PAGE_VIEW = 'PAGE_VIEW'
}

export interface EventDetail {
  elementId?: string
  elementClass?: string
  elementText?: string
  pageUrl?: string
  clickcontext?: ClickContext
}

export interface Event {
  eventType?: EventType
  eventTime?: string | null
  event: EventDetail
}

export interface SentEvent extends Event {
  userId: string
  queueId: string
}

class EventStack extends Dexie {
  events!: Dexie.Table<Event, string>

  constructor() {
    super('eventStack')
    this.version(1).stores({
      events: 'queueId'
    })
  }
}

class EventClient extends RestClient {
  private eventStack: Array<SentEvent> = []
  private queuedEventStack: Array<SentEvent> = []
  private hasExecuted = false
  private db: Dexie

  private readonly enabled: boolean =
    (process.env.NEXT_PUBLIC_ANALYTICS_ENABLED as string) === 'true'

  constructor(url: string) {
    super(url)

    if (!this.enabled) console.warn('🚨 Analytics not enabled')

    this.db = new EventStack()

    this.loadCachedEvents()
  }

  public click = async (event: EventDetail, userId: string) => {
    await this.queue({
      event,
      eventTime: DateTime.now().toISO(),
      eventType: EventType.CLICK,
      userId,
      queueId: uuidv4()
    })
  }

  public pageView = async (event: EventDetail, userId: string) => {
    await this.queue({
      event,
      eventTime: DateTime.now().toISO(),
      eventType: EventType.PAGE_VIEW,
      userId,
      queueId: uuidv4()
    })
  }

  private queue = async (event: SentEvent) => {
    if (!this.enabled) return

    await this.cacheEvent(event)

    if (!this.hasExecuted) {
      this.eventStack.push(event)
      this.hasExecuted = true
      await this.executePromisesInSeries()
    } else {
      // The queued event stack holds new events once async operations are being processed
      // so as not to modify the existing array
      this.addToSubsidiaryStack(event)
    }
  }

  private executePromisesInSeries = async () => {
    for (const event of this.eventStack) {
      try {
        await this.POST('/api/v1/app/event', event, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        await this.removeEventFromCache(event.queueId)
      } catch (error) {
        console.error(error)
        // Do nothing so that the event stays in cache
      }
    }

    if (this.queuedEventStack.length > 0) {
      this.eventStack = this.queuedEventStack
      this.queuedEventStack = []
      await this.executePromisesInSeries()
    } else {
      this.hasExecuted = false
      this.eventStack = []
    }
  }

  private addToSubsidiaryStack = (event: SentEvent) => {
    this.queuedEventStack.push(event)
  }

  private cacheEvent = async (event: SentEvent) => {
    await this.db.table('events').put(event)
  }

  private removeEventFromCache = async (queueId: string) => {
    await this.db.table('events').delete(queueId)
  }

  private loadCachedEvents = async () => {
    if (!this.enabled) return

    this.eventStack = await this.db.table('events').toArray()

    if (this.eventStack.length > 0) {
      this.hasExecuted = true
      await this.executePromisesInSeries()
    }
  }
}

export default EventClient
